@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Bold.woff2') format('woff2'), url('./fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-SemiBold.woff2') format('woff2'), url('./fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.woff2') format('woff2'), url('./fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kollektif';
  src: url('./fonts/Kollektif.woff2') format('woff2'), url('./fonts/Kollektif.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kollektif';
  src: url('./fonts/Kollektif-Bold.woff2') format('woff2'), url('./fonts/Kollektif-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@tailwind base;
@tailwind components;

* {
  font-family: Montserrat;
}

.draw-arrow {
  stroke-width: 5;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
  animation-name: draw;
  animation-delay: 0.6s;
}
.draw-arrow.tail-1 {
  animation-delay: 1s;
}
.draw-arrow.tail-2 {
  animation-delay: 1.2s;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

/* Hide ugly browser's controls on input[type="number"] */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@tailwind utilities;
